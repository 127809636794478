/***** Homepage *****/

.main.main--home {
  &:after {
    background-image: url("../images/home-bg.jpg");
    @include max($xxxl) {
      background-size: 70% auto;
      background-position: 100% 0;
      left: 0;
    }
    @include max($lg) {
      background-size: 90% auto;
      background-position: 0 0;
      left: 30%;
    }
    @include max($sm) {
      left: 20%;
      background-size: 110% auto;
      background-position: 0 0;
    }
  }
}

.section-intro {
  position: relative;
  z-index: 3;
  padding: 0 0 70px;
  // @include max($lg) {
  //   padding: 0 0 150px;
  // }
  .intro-i {
  }
  h1 {
    max-width: 330px;
    br {
    }
  }

  .text {
    font-size: 18px;
    max-width: 330px;
    @include max($lg) {
      font-size: 16px;
    }
    p {
      max-width: 300px;
    }
    ul {
      li {
      }
    }
  }
  .video {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 44px;
    @include max($sm) {
      margin: 30px -20px 0;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  &.section-intro--home {
    .text {
      font-size: 16px;
    }
  }
  &.section-intro--service {
    margin-bottom: -3.5vw;
  }
  &.section-intro--team {
    padding: 0 0 30px;
  }
  &.section-intro--about {
    padding-bottom: 77px;
    .intro-i {
    }
    p {
      max-width: 330px;
    }
  }
}

.section-services {
  background-size: 100% auto;
  position: relative;
  padding: 160px 0 170px;
  z-index: 2;
  @include max($lg) {
    padding-top: 100px;
  }
  @include max($sm) {
    padding-top: 60px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    top: -220px;
    bottom: 0;
    z-index: 1;
    background: url("../images/home-top-bg.svg") 0 0 no-repeat;
    background-size: 105% auto;
    @include min($huge) {
      top: calc(-210px - 4vw);
    }
    @include min($xxhuge) {
      top: calc(-210px - 8vw);
    }
    @include max($lg) {
      top: -120px;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: calc(45vw - 220px);
    bottom: 0;
    left: 0;
    width: 100%;
    background: $brownbg;
    z-index: 0;
    @include max($lg) {
      top: calc(45vw - 120px);
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  // .icon-square {
  //   width: 50px;
  //   height: 50px;
  //   margin-bottom: 25px;
  // }

  .services-i {
    position: relative;
    margin-bottom: -230px;
  }
  h2 {
    color: #fff;
    margin-bottom: 70px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }

  .service-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px -60px;
    @include max($xxl) {
      margin: 0 -15px -30px;
    }
    .service-item {
      width: 33.3333%;
      flex: 0 0 33.3333%;
      padding: 0 30px 60px;
      display: flex;
      align-items: stretch;
      @include max($xxl) {
        padding: 0 15px 30px;
      }
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      .card.card--service {
        width: 100%;
      }
    }
  }

  .info-consult {
    width: 280px;
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 1;
    color: #fff;
    @include max($md) {
      position: relative;
      bottom: auto;
      right: auto;
      margin-top: 40px;
    }
    p {
      margin-bottom: 45px;
    }
    .btn {
    }
  }

  .services-bottom-bg {
    background: $darkbg;
    height: 170px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 3.4vw;
      background: linear-gradient(
        1deg,
        #353535 50%,
        transparent calc(50% + 1px)
      );
    }
    .container {
      position: relative;
    }
  }
}

.section-info-bottom {
  background: $darkbg;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 25px 0 75px;
  @include max($lg) {
    padding: 40px 0 60px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 3.4vw;
    background: linear-gradient(1deg, #353535 50%, transparent calc(50% + 1px));
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 10vw;
    top: 100%;
    background: linear-gradient(
      175deg,
      #353535 50%,
      transparent calc(50% + 1px)
    );
  }
  &.no-top-bg {
    &:before {
      display: none;
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .info-consult {
    width: 280px;
    margin-left: auto;
    // margin-top: 40px;
    @include max($sm) {
      margin: 0;
      width: 100%;
    }
    p {
      margin-bottom: 45px;
    }
    .btn {
    }
  }

  .posts {
    padding: 95px 0 0;
    h2 {
      margin-bottom: 50px;
      @include max($lg) {
        margin-bottom: 30px;
      }
    }

    .post-list {
      display: flex;
      flex-wrap: wrap;
      gap: 130px;
      margin: 0 0 -30px;
      @include max($lg) {
        gap: 10%;
      }
      @include max($sm) {
        gap: 40px;
      }
      .post-item {
        width: 290px;
        margin-bottom: 30px;
        @include max($lg) {
          width: 45%;
          flex: 0 0 45%;
        }
        @include max($sm) {
          width: 100%;
          flex: 0 0 100%;
          margin: 0;
        }
        .card.card--post {
        }
      }
    }
  }

  .info-bottom-bg {
    width: 218px;
    height: 530px;
    background: url("../images/footer-squares.svg") 0 0 no-repeat;
    background-size: contain;
    position: absolute;
    right: calc(50% + 520px);
    bottom: -65px;
    z-index: 1;
    @include max($xxl) {
      right: auto;
      left: -20px;
      height: 300px;
      width: 150px;
      opacity: 0.5;
    }
    @include max($sm) {
      bottom: -20px;
    }
  }
  &.section-info-bottom--home {
  }
  &.section-info-bottom--service {
    padding-top: 90px;
    h2 {
      max-width: 600px;
    }
    .info-projects-row {
      display: flex;
      padding-bottom: 50px;
      @include max($xl) {
        display: block;
      }
    }
    .project-list {
      display: flex;

      margin: 0 -20px;
      @include max($xl) {
        margin-bottom: 40px;
        margin: 0 -15px;
      }
      @include max($xs) {
        display: block;
      }
      .project-item {
        width: 400px;
        flex: 0 0 400px;
        padding: 0 20px;
        @include max($xl) {
          width: 50%;
          flex: 0 0 50%;
          padding: 0 15px;
        }
        @include max($xs) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
      a {
      }
    }
    .info-consult {
      align-self: flex-end;
      padding-bottom: 14px;
      @include max($xl) {
        margin: 30px 0 0;
      }
    }
  }
  .posts {
    @include max($xxl) {
      padding-top: 40px;
    }
  }
}
