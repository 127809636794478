/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1260px;
  @include max($lg) {
    padding: 0 20px;
  }
}
.wrapper {
  overflow: hidden;
}

.main {
  padding-top: 270px;
  transition: all 0.2s;
  position: relative;
  // overflow-x: hidden;
  // overflow-y: visible;

  @include max($xxl) {
    padding: 157px 0 0;
  }
  @include max($sm) {
    padding: 120px 0 0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 70% auto;
    z-index: 0;
    @include max($xxxl) {
      left: 20%;
      background-size: 140% auto;
      background-position: 0 0;
      opacity: 0.7;
    }
    @include max($lg) {
      left: 30%;
      background-size: 160% auto;
    }
    @include max($sm) {
      left: 40%;
      background-size: 180% auto;
    }
  }
}
section {
  position: relative;
  z-index: 1;
}
