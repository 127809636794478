/***** Simple elements, atoms *****/

.icon {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
}

.highlight {
  color: $primary;
}

.text {
  font-size: 18px;
  max-width: 900px;
  @include max($lg) {
    font-size: 16px;
  }
  ul {
    margin: 15px 0;
    list-style: none;
    padding: 0;
    li {
      padding-left: 25px;
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        width: 15px;
        height: 15px;
        background: url("../images/icons/square.svg") 0 0 no-repeat;
        background-size: contain;
      }
    }
  }

  h2 {
    position: relative;
    height: 20.7vw;
    display: flex;
    align-items: center;
    color: #fff;
    @include max($xxl) {
      height: 30vw;
      margin: 0;
    }
    @include max($lg) {
      height: 40vw;
    }
    @include max($sm) {
      height: auto;
      min-height: 50vw;
      padding: 10vw 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: calc(50% - 50vw + 150px);
      width: 100vw;
      height: 100%;
      background: url("../images/heading-bg.svg") 50% 50% no-repeat;
      background-size: 100% auto;
      @include max($xxl) {
        width: 120vw;
        left: calc(50% - 50vw);
      }
      @include max($md) {
        width: 100vw;
      }
      @include max($lg) {
        background-size: 150% auto;
      }
      @include max($sm) {
        background-size: 250% auto;
      }
      @include max($xxs) {
        background-size: 320% auto;
      }
    }

    &:not(:first-child) {
      margin-top: 90px;
      @include max($xxl) {
        margin-top: 50px;
      }
    }
  }

  p {
  }

  img {
    margin: 30px 0;
  }

  h3 {
    margin: 40px 0 20px;
  }
}

.highlight-block {
  background: url("../images/highlight-bg.svg") 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 32px;
  margin: 50px 0 50px -32px;
  @include max($xxl) {
    padding: 20px;
    margin-left: 30px 0 30px -20px;
  }
  @include max($lg) {
    margin-left: 0;
  }
}
