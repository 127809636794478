/**** Form elements *****/

.form-group {
  margin-bottom: 25px;
  label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
}
.form-control {
  border: 1px solid #000;
  background: #fff;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 23px;
  height: 45px;
  font-size: 18px;
}
textarea.form-control {
  height: 230px;
}

.form-checkbox {
  font-size: 20px;
  margin-bottom: 25px;
}
