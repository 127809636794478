/***** Components, common blocks *****/

.card.card--service {
  background: $primary;
  display: flex;
  align-items: stretch;
  a {
    display: block;
    width: 100%;
    &:hover {
      box-shadow: 0 0 25px rgba(#000, 0.47);
    }
  }
  .card-img {
    line-height: 0;
    position: relative;
    padding-bottom: 83.3333%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include max($lg) {
      }
    }
  }
  .card-body {
    padding: 22px 70px 20px 35px;
    color: #fff;
    position: relative;
    @include max($lg) {
      padding: 20px 50px 20px 20px;
    }
    .card-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .card-desc {
      font-size: 20px;
    }
    .card-more {
      position: absolute;
      top: 23px;
      right: 36px;
      width: 16px;
      height: 24px;
      @include max($lg) {
        top: 20px;
        right: 20px;
      }
      .icon {
        height: 24px;
        &.icon-angle-right-sq {
        }
      }
    }
  }
}

.card.card--post {
  .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 1.1;
    a {
    }
  }
  .card-desc {
    margin-bottom: 25px;
    line-height: 1.1;
  }
  .btn {
  }
}

.card.card--project {
  display: flex;
  align-items: stretch;
  a {
    background: $brownbg;
    &:hover {
      opacity: 0.9;
    }
  }
  .card-img {
    line-height: 0;
    display: block;
    padding-bottom: 83.3333%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .card-body {
    padding: 22px 30px;
    font-size: 20px;
    line-height: 1.1;
    color: #fff;
    @include max($lg) {
      font-size: 16px;
      padding: 20px;
    }
  }
}

.card.card--team {
  background: $darkbg;
  color: #fff;
  .card-photo {
    position: relative;
    padding-bottom: 83.3333%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-body {
    padding: 25px 35px;
    min-height: 151px;
    @include max($xxl) {
      padding: 20px;
    }
    @include max($sm) {
      min-height: 0;
    }
  }
  .card-name {
    font-size: 20px;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 15px;
    @include max($sm) {
      margin-bottom: 10px;
    }
  }
  .card-desc {
    font-size: 20px;
  }
}

.card.card--feature {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  @include max($sm) {
    flex-direction: column;
  }
  .card-body {
    order: 1;
    background: $lightbg;
    padding: 38px 40px;
    width: 65.5%;
    flex: 0 0 65.5%;
    position: relative;
    @include max($lg) {
      width: 50%;
      flex: 0 0 50%;
      padding: 30px 20px 20px;
    }
    @include max($sm) {
      order: 2;
      width: 100%;
      flex: auto;
      padding: 20px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: calc(100% - 1px);
      top: -1px;
      bottom: -1px;
      width: 4.5%;
      background: url("../images/feature-bg.svg") 100% 0 no-repeat;
      background-size: cover;
      @include max($lg) {
        width: 35px;
      }
      @include max($sm) {
        left: -1px;
        right: -1px;
        bottom: auto;
        top: -5vw;
        width: auto;
        height: 5vw;
      }
    }
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 34px;
    @include max($lg) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  .card-desc {
    max-width: 350px;
    font-size: 18px;
    @include max($lg) {
      font-size: 16px;
    }
  }
  .card-img {
    order: 2;
    background: $darkbg;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34.5%;
    flex: 0 0 34.5%;
    @include max($lg) {
      width: 50%;
      flex: 0 0 50%;
      padding: 20px;
    }
    @include max($sm) {
      order: 1;
      width: 100%;
      flex: auto;
      padding: 20px 20px 40px;
    }
    img {
      @include max($lg) {
        width: 120px;
      }
    }
  }
  &.card--feature--right {
    .card-img {
      order: 1;
    }
    .card-body {
      order: 2;
      padding: 38px 40px 38px 110px;
      @include max($lg) {
        padding: 30px 20px 20px 20px;
      }
      @include max($sm) {
        padding: 20px;
      }
      &:after {
        transform: rotate(180deg);
        left: auto;
        right: calc(100% - 1px);
        @include max($sm) {
          left: -1px;
          right: -1px;
          transform: none;
          top: -5vw;
        }
      }
    }
  }
}
