/*** About ***/

.main.main--about {
  &:after {
    background-image: url("../images/about-bg.jpg");
    @include minmax($md, $xxxl) {
      background-size: 80% auto;
    }
  }
}

.section-about {
  position: relative;
  padding: 0 0 40px;
  z-index: 1;
  .text {
    margin-bottom: 60px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }
  .feature-list {
    margin: 0;
    padding-bottom: 70px;
    @include max($lg) {
      padding-bottom: 20px;
    }
    .feature-item {
      padding: 0 0 40px;
    }
  }
}
.section-standards {
  padding: 0 0 130px;
  position: relative;
  z-index: 1;
  @include max($lg) {
    padding: 0 0 60px;
  }
  h2 {
    margin-bottom: -90px;
    &:after {
      top: 20px;
    }
  }
  img {
  }
}
