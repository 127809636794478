/***** Header *****/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 105px 0 0;
  color: #fff;
  z-index: 10;
  transition: all 0.3s;
  @include max($xxl) {
    padding: 0;
    background: $darkbg;
    height: 60px;
  }

  .header-i {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      height: 105px;
      left: 0;
      right: 397px;
      background: $darkbg;
      z-index: 0;
      transition: all 0.3s;
      @include max($xxl) {
        height: 60px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 197px;
      top: 0;
      width: 200px;
      height: 105px;
      background: url("../images/header-bg.png") 0 0 no-repeat;
      z-index: 0;
      transition: all 0.3s;
      @include max($xxl) {
        height: 60px;
      }
    }
  }

  .header-nav {
    margin-right: auto;
    display: flex;
    align-items: center;
    order: 2;
    gap: 60px;
    padding: 34px 215px 34px 30px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    @include max($xxl) {
      padding: 14px 130px 14px 0;
    }
    @include max($lg) {
      opacity: 0;
      visibility: hidden;
      background: $darkbg;
      color: $primary;
      position: fixed;
      top: 60px;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      transition: all 0.3s;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      @include max($lg) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
        margin: auto 0;
      }
      li {
        margin: 0;
        > a,
        > span {
          padding: 5px 15px;
          line-height: 25px;
          border: 1px solid transparent;
          white-space: nowrap;
          color: #fff;
          display: block;
          @include max($xxl) {
            padding: 3px 10px;
            line-height: 24px;
          }
          @include max($lg) {
            color: #fff;
          }
        }
        > a {
          color: #fff;
          &:hover {
            color: $primary;
          }
        }
        > span {
          color: $primary;
          border-color: #fff;
          cursor: default;
        }
      }
    }

    .header-lang {
      a {
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .toggler {
    display: none;
    margin: 12px auto 0 0;
    order: 1;
    width: 36px;
    height: 36px;
    position: relative;
    cursor: pointer;
    @include max($lg) {
      display: block;
    }
    .icon {
      position: absolute;
      overflow: visible;
      margin: auto;
      // width: 100%;
      // height: 100%;
      fill: #fff;
      transition: all 0.1s;
      &.icon-menu {
        width: 36px;
        height: 19px;
        margin-top: 8px;
      }
      &.icon-cross {
        opacity: 0;
        visibility: hidden;
        width: 24px;
        height: 24px;
        margin: 6px;
      }
    }
    &:hover {
      .icon {
        opacity: 0.8;
      }
    }
  }
  .logo {
    order: 3;
    line-height: 0;
    width: 197px;
    flex: 0 0 197px;
    height: 130px;
    position: relative;
    transition: all 0.3s;
    margin-bottom: -25px;
    @include max($xxl) {
      height: 86px;
      width: 120px;
      flex: 0 0 120px;
      margin: -30px 0 5px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

body.is-scrolled {
  .header {
    padding-top: 0;
    background: $darkbg;
    .header-i {
      &:before {
        @include min($xxl) {
          height: 78px;
        }
      }
      &:after {
        @include min($xxl) {
          height: 78px;
        }
      }
    }
    .header-nav {
      @include min($xxl) {
        padding: 20px 215px 20px 30px;
      }
    }
    .logo {
      @include min($xxl) {
        height: 58px;
        width: 89px;
        flex: 0 0 89px;
        margin: 10px;
      }
    }
  }
}
body.is-mobile-menu {
  overflow: hidden;
  .header {
    .header-nav {
      opacity: 1;
      visibility: visible;
    }
    .toggler {
      .icon {
        &.icon-menu {
          opacity: 0;
          visibility: hidden;
        }
        &.icon-cross {
          opacity: 1;
          visibility: visible;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
