/*** Contact ***/

.main.main--contact {
  &:after {
    background-image: url("../images/contact-bg.jpg");
    @include minmax($md, $xxxl) {
      background-size: 80% auto;
    }
  }
}

.section-contact {
  margin: 250px 0 0;
  padding: 0 0 120px;
  @include max($huge) {
    margin: 50px 0 0;
  }
  @include max($lg) {
    margin: 0;
    padding: 0 0 50px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 122px;
    background: $lightbg;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .contact-t {
    position: relative;
    padding: 35px 0 70px 95px;
    margin: 0 0 40px calc(50% - 95px);
    color: #fff;
    @include max($lg) {
      padding: 30px 0 30px 20px;
      margin: 0 0 40px calc(20% - 30px);
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 36px;
      height: 100%;
      background: linear-gradient(83deg, transparent 50%, $darkbg 52%);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: calc(50vw + 95px);
      background: $darkbg;
      @include max($lg) {
        width: calc(80vw + 30px);
      }
    }
    .contact-t-i {
      position: relative;
      z-index: 1;
    }
    h2 {
      font-size: 30px;
      margin: 0 0 30px;
      @include max($lg) {
        font-size: 24px;
      }
    }
    p {
      max-width: 350px;
      margin: 0;
    }
  }
  .contact-b {
    display: flex;
    @include max($lg) {
      flex-direction: column;
    }
  }
  .contact-info {
    width: 380px;
    flex: 0 0 380px;
    font-size: 18px;
    margin-right: 40px;
    @include max($lg) {
      order: 2;
      width: 100%;
      margin: 0;
    }
    p {
      img {
        vertical-align: middle;
        width: 22px;
        height: 22px;
      }
    }
    a {
      color: #000;
      &:hover {
        opacity: 0.8;
      }
    }
    .contact-info-b {
      margin-top: 50px;
      a {
        text-decoration: underline;
      }
    }
  }
  .contact-form {
    width: 50%;
    flex: 0 0 50%;
    margin-left: auto;
    @include max($lg) {
      order: 1;
      width: 100%;
      margin: 0 0 40px;
    }
    form {
      position: relative;
      max-width: 500px;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .captcha-logo {
    margin: -50px 0 0;
    text-align: right;
    line-height: 0;
    @include max($sm) {
      margin: 0;
    }
  }
}
