/* Color */
$primary: #ff8200;
$primaryhover: #fc9f3b;
$lightbg: #f2eee9;
$brownbg: #b1987f;
$darkbg: #353535;

$font-heading: "Rajdhani", sans-serif;
$font-basic: "Rajdhani", sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
