/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  padding: 5px 25px;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 25px;
  height: 37px;
  font-weight: bold;
  font-size: 20px;
  &:hover {
  }
  &.btn-primary {
    border-color: $primary;
    background: $primary;
    color: #fff;
    &:hover {
    }
  }
  &.btn-secondary {
    &:hover {
    }
  }
  &.btn-outline-white {
    color: $primary;
    border-color: #fff;
    background: none;
    &:hover {
      background: $primary;
      color: #fff;
      border-color: $primary;
    }
  }
  &.btn-outline-black {
    color: $primary;
    border-color: #353535;
    background: none;
    &:hover {
      background: #353535;
    }
  }
  &.btn-sm {
    line-height: 18px;
    font-size: 16px;
    padding: 3px 12px;
    height: 26px;
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-centered {
    justify-content: center;
  }
}
