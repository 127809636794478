/***** Footer *****/

.footer {
  padding: 100px 0 80px;
  position: relative;
  z-index: 2;
  @include min($xxhuge) {
    padding-top: 140px;
  }
  @include max($sm) {
    padding: 80px 0 40px;
  }
  a {
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
  .footer-t {
    max-width: 300px;
    margin: 0 0 30px auto;
    @include max($lg) {
      max-width: 45%;
    }
    @include max($sm) {
      max-width: 100%;
      margin: 0 0 30px;
    }
  }
  h2 {
    color: $primary;
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }

  .phone-w {
    display: flex;
    align-items: center;
    gap: 10px;
    .phone {
      font-weight: bold;
      font-size: 20px;
    }

    .whatsapp {
      a {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
        }
        span {
        }
      }
    }
  }
  .footer-b {
    max-width: 640px;
    margin: 0 0 0 auto;
    display: flex;
    gap: 40px;
    @include max($lg) {
      max-width: 100%;
      margin: 0;
      display: block;
    }
  }
  .footer-info {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    @include max($lg) {
      gap: 10%;
    }
    @include max($sm) {
      gap: 40px;
    }
    .footer-info-col {
      width: 300px;
      flex: 0 0 300px;
      @include max($lg) {
        width: 45%;
        flex: 0 0 45%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      &:first-child {
        text-align: right;
        @include max($sm) {
          text-align: left;
        }
      }
    }
  }
}

.support-widget {
  position: fixed;
  z-index: 10;
  top: 85vh;
  right: 0;
  gap: 16px;
  .support-widget-i {
    transform: rotate(-90deg);
    position: relative;
    width: 92px;
    height: 92px;
    @include max($xxl) {
      width: 64px;
      height: 64px;
    }
    @include max($lg) {
      width: 48px;
      height: 48px;
    }
  }
  .widget-icon {
    width: 100%;
    height: 100%;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      @include max($xxl) {
        width: 60%;
        height: 60%;
      }
    }
  }
  .widget-text {
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 16px;
    @include max($xxl) {
      font-size: 20px;
      margin-left: 12px;
    }
    @include max($lg) {
      font-size: 16px;
      margin-left: 8px;
    }
  }
}
