/*** Services ***/

.main.main--service {
  &:after {
    background-image: url("../images/service-bg.jpg");
    @include minmax($md, $xxxl) {
      background-size: 80% auto;
    }
  }
}

.section-text {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    top: 10vw;
    bottom: 0;
    background: #fff;
    @include max($xxl) {
      top: 15vw;
    }
    @include max($lg) {
      top: 20vw;
    }
    @include max($sm) {
      top: 25vw;
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }
}

.section-gallery {
  padding: 0 0 150px;
  @include max($lg) {
    padding: 0 0 80px;
  }
  h2 {
    margin-bottom: -70px;
    &:after {
      top: 20px;
    }
  }
  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    margin: 0 -20px -40px;
    .gallery-item {
      width: 25%;
      flex: 0 0 25%;
      padding: 0 20px 40px;
      @include max($xl) {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 15px 30px;
      }
      @include max($md) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($xs) {
      }
      img {
        width: 100%;
        height: 203px;
        object-fit: cover;
        @include max($xs) {
          height: 150px;
        }
      }
    }
  }
}

/*** Gallery ***/
.pswp {
  --pswp-placeholder-bg: #353535;
  // max-width: 1200px;

  // left: 50%;
  // transform: translateX(-50%) !important;
}
.pswp__bg {
  background: #353535;
}
.pswp__scroll-wrap {
}
.pswp__item {
  .pswp__zoom-wrap {
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      left: 840px;
      top: -9px;
      width: 195px;
      height: 128px;
      background: url("../images/logo.svg") 0 0 no-repeat;
      background-size: contain;
      @include max($xl) {
        left: 65vw;
        width: 20vw;
      }
      @include max(900px) {
        left: 75vw;
      }
    }
  }
}
.pswp img {
  object-fit: contain;
}

.pswp.pswp--zoomed-in {
  .pswp__item .pswp__zoom-wrap::after {
    opacity: 0;
    visibility: hidden;
  }
}

.pswp__button--arrow {
  width: 140px;
  height: 60px;
  margin: -30px 0 0;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s !important;
  @include max($lg) {
    width: 60px;
  }
  &:hover {
    margin: -30px 0 0;
    padding: 10px;
    opacity: 0.8;
  }
  &:before,
  &:after {
    font-weight: bold;
    font-size: 20px;
    color: $primary;
    @include max($lg) {
      display: none;
    }
  }
  svg {
    width: 17px;
    height: 25px;
  }
  &.pswp__button--arrow--prev {
    left: 120px;
    @include max($xxxl) {
      left: 0;
    }
    &:after {
      content: "Iepriekšējā";
    }
  }
  &.pswp__button--arrow--next {
    right: 120px;
    justify-content: flex-end;
    @include max($xxxl) {
      right: 0;
    }
    &:before {
      content: "Nākamā";
    }
  }
}
.pswp__button--close {
  right: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  gap: 8px;
  opacity: 1;
  transition: all 0.2s !important;
  width: 140px;
  height: 56px;
  margin: 0;
  @include max($xxxl) {
    right: 0;
  }
  @include max($lg) {
    width: 52px;
  }
  &:hover {
    opacity: 0.8;
    margin: 0;
    padding: 10px;
  }
  &:before {
    content: "Aizvērt";
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    @include max($lg) {
      display: none;
    }
  }
  .pswp__icn {
    position: relative;
    top: initial;
    left: initial;
    width: 26px;
    height: 26px;
  }
}

.pswp__counter {
  font-size: 20px;
  font-weight: bold;
}
