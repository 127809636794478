/*** Team ***/

.main.main--team {
  &:after {
    background-image: url("../images/team-bg.jpg");
    @include minmax($md, $xxxl) {
      background-size: 80% auto;
    }
  }
}

.section-team {
  padding: 0 0 290px;
  position: relative;
  z-index: 1;
  @include max($xxl) {
    padding: 0 0 100px;
  }
  .container {
    max-width: 1220px;
  }
  .team-list {
    margin: 0 -20px -40px;
    display: flex;
    flex-wrap: wrap;
    @include max($xxl) {
      margin: 0 -10px -20px;
    }
    .team-item {
      width: 33.3333%;
      flex: 0 0 33.3333%;
      padding: 0 20px 40px;
      @include max($xxl) {
        padding: 0 10px 20px;
      }
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
